import Splide from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

document.addEventListener("DOMContentLoaded", function () {
  if (document.getElementsByClassName("splide-logos")) {
    var sliders = document.querySelectorAll(".splide-logos");
    sliders.forEach(function (slider) {
      var slide = new Splide(slider as HTMLElement, {
        type: "loop",
        pagination: false,
        arrows: false,
        autoWidth: true,
        gap: "52px",
        drag: "free",
        snap: false,
        autoScroll: {
          speed: 0.5,
          pauseOnHover: false,
          pauseOnFocus: false,
        },
      }).mount({ AutoScroll });
    });
  }
});
