//custom code
import './custom/mobile-menu';
import './custom/scroller';
import './custom/splide';
import './custom/heading-animation';
import './custom/scroll-opacity';

//libraries
import './lib/fslightbox';

