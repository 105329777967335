document.addEventListener("DOMContentLoaded", function () {
    //logo opacity 0 on scroll
    var toggle = document.querySelector(".site-header__nav--mobile__toggle");
    var main = document.querySelector(".site-main");
  
    window.addEventListener("scroll", function () {
      var offset = main.getBoundingClientRect();
  
      if (offset.top <= 30 && offset.top >= -3850) { //Jouw offset top kan afwijken, kan je finetunen met een console.log(offset.top); 
        //class togglen, ik toggle een opacity
        toggle.classList.add('onscroll');
      } else {
        toggle.classList.remove('onscroll');
      }
    });
  });